import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import HeaderVideo from "../components/HeaderVideo"

import parse from "html-react-parser"

//import ImageWithAnimation from "../components/ImageWithAnimation";
import { textAppearAnimationAll } from "../components/textAppearAnimation"

const ServicesPage = ({ data, location }) => {

  const siteTitle = data.site.siteMetadata.title
  const content = data.wpPage
/*
  const featuredImage = {
    fluid: content.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: content.featuredImage?.node?.alt || ``,
  }
*/
  const belowLogoTitle = {
    heroTitle: content.customFieldsServices.belowLogoTitle,
    heroTitleItalic: '', 
    heroContent: ''
  }

  useEffect(() => {
    textAppearAnimationAll("h2");
    textAppearAnimationAll("h4");
  }, []);

  return (
    <Layout title={siteTitle} isHomePage={true} page={'services'}>
      <Seo title="Services" />
      <Hero fields={content.customFieldsHero} />

      <div className={"padding-bottom-112"}>
        <HeaderVideo video="678032570" padding={"padding-bottom-64"} />
      </div>

      <Hero fields={belowLogoTitle} />


      <div className={"container"}>
        <div className={"content padding-bottom-112"}>
            {parse(content.content)}
        </div>

        <div className={'services'}>
          {content.customFieldsServices.services.map((node) => {
            return (
              <div key={node.title} className={'service section-up padding-bottom-112'}>
                <h2 className={"padding-bottom-16"}>{node.service}</h2>
                <h3 className={"padding-bottom-48 h3"}>{node.title}</h3>
                <div className={"grid section-up"}>
                  <div className={"list"}>
                    {node.services.map((item, index) => {
                      return (
                        <div key={index} className={"grid"}>
                          <div>{index+1}</div>
                          <div>{item.name}</div>
                        </div>
                      )
                    })}
                  </div>
                  <div className={"description"}>
                  {parse(node.description)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>

      </div>
    </Layout>
  )
}

export default ServicesPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        wpPage(slug: { eq: "work" }) {
            title
            slug
            excerpt
            content
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(maxHeight: 800) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
            customFieldsHero {
                heroTitle
                heroTitleItalic
                heroContent
            }
            
            customFieldsServices {
                services {
                    description
                    service
                    title
                    services {
                        name
                    }
                }
                belowLogoTitle
            }
        }
    }
`